import React from "react";
import { header } from "../../Images";

const Header = () => {
  return (
    <div className="header" style={{ backgroundImage: `url(${header})` }}>
      <div className="header-content">
        <h1 className="header-content__title">WAFI TOP</h1>
        <p className="header-content__description">
          Networking and programming solutions
        </p>
        <p className="header-content__ifno">
          تأسست شركة وافي القمة التجارية في عام 2007م بإدارة فريق متخصص ذو خبرة
          عالية في تقنية المعلومات والتقنيات الناشئة ، لتلبي كافة متطلبات
          الأفراد والشركات والمؤسسات الحكومية والخاصة في مجال تقنية المعلومات
          ولتقدم حلول برمجية متميزة ذات جودة ونوعية عالية .
        </p>
        <br />
        <p className="header-content__actions">
          اطلب الأن انظمة واس الداعمة للفاتورة الألكترونية المتوافقة مع هيئة
          الزكاة و الضريبة
        </p>
      </div>
    </div>
  );
};

export default Header;
