import React from "react";
import { Link } from "react-router-dom";

const FooterList = (props) => {
  return (
    <div className="footer-list__container">
      <h6 className="footer-list__container__title">{props.title}</h6>
      <ul className="footer-list__container__list">
        {props?.links?.map((link) => {
          return (
            <li className="footer-list__container__list__item" key={link.id}>
              <Link
                to={link.path}
                className="footer-list__container__list__item__link"
              >
                {link.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(FooterList);
