export const FooterData = [
  {
    links: [
      {
        id: "1",
        name: "الرئيسية",
        path: "/",
      },

      {
        id: "3",
        name: "عملائنا",
        path: "/#",
      },
      {
        id: "4",
        name: "الدعم",
        path: "/support",
      },
      {
        id: "5",
        name: "تواصل معنا",
        path: "/contact",
      },
    ],
  },
];
