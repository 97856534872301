import React from "react";
import ROUTES from "../../Routes/Routes";
import Logo from "../../Assets/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import MobileSideNav from "../../components/MobileSideNav/MobileSideNav";
import { motion } from "framer-motion";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import RolesLayout from "../RolesLayout/RolesLayout";
import { ROLES } from "../../Constant/Roles";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../Redux/Slices/UserSlice";

const Navbar = () => {
  const location = useLocation();

  const [navstate, setNavState] = React.useState(false);
  const User = useSelector((state) => state?.user?.user?.token);

  const dispatch = useDispatch();
  const navAuth = () => {
    if (User) {
      dispatch(userActions.logOut());
    }
  };
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);

    if (element) {
      const scroll = element.id === "products" ? "start" : "center";

      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: scroll,
        inline: "center",
      });
    }
  };
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <motion.div
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: -150, opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.4, type: "spring" }}
        >
          <Link to="/">
            <img src={Logo} alt="Wafitop" className="navbar-left__logo" />
          </Link>
        </motion.div>
        <MobileSideNav isActive={navstate} setNavState={setNavState} />
        <ul className="navbar-left__menu">
          {ROUTES?.map((route) => {
            return (
              !route.isHidden && (
                <motion.li
                  animate={{ x: 0, opacity: 1 }}
                  initial={{ x: -150, opacity: 0 }}
                  transition={{ delay: 0.5, duration: 0.4, type: "spring" }}
                  className="navbar-left__menu__item"
                  key={route.id}
                  onClick={() => handleClickScroll(route.id)}
                >
                  <Link
                    to={route.path}
                    className={`navbar-left__menu__item-link ${
                      location.pathname === route.path && "active"
                    }`}
                  >
                    {route.name}
                  </Link>
                </motion.li>
              )
            );
          })}

          <RolesLayout roles={[ROLES.ADMIN, ROLES.EMPLOYER]}>
            <motion.li
              animate={{ x: 0, opacity: 1 }}
              initial={{ x: -150, opacity: 0 }}
              transition={{ delay: 0.5, duration: 0.4, type: "spring" }}
              className="navbar-left__menu__item"
            >
              <Link to={"/admin"} className="navbar-left__menu__item-link">
                صفحه الأدمن
              </Link>
            </motion.li>
          </RolesLayout>
          <div style={{ marginRight: "2rem", alignSelf: "center" }}>
            <Button
              onClick={navAuth}
              title={User ? "تسجيل خروج" : "تسجيل دخول"}
              styles="btn btn--m btn--primary"
              path={User ? "#" : "/login"}
            />
          </div>
        </ul>
      </div>
      <div className="navbar-right">
        <Button
          icon={navstate ? faClose : faBars}
          styles={"btn navbar-right__mobile-menu"}
          name="mobile-menu"
          onClick={() => setNavState(!navstate)}
        />

        <div className="navbar-right__social">
          <SocialLinks backgroundColor={"var(--color-black-light)"} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
