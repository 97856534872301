import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function GoalsItem(props) {
  return (
    <div className="goal-item">
      <div className="goal-item__top">
        <div className="goal-item__top__icon">
          <FontAwesomeIcon
            icon={props?.icon}
            className="goal-item__top__icon-icon"
          />
        </div>
        <h2 className="goal-item__top__title">{props.title}</h2>
      </div>
      <div className="goal-item__bottom">
        <p className="goal-item__bottom__text">{props.text}</p>
      </div>
    </div>
  );
}

export default GoalsItem;
