import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";

export const contactThuhk = createAsyncThunk("contactUs", async (payload) => {
  const response = await axios.post(`${BASE_URL}/contacts`, payload, {});
  return response.data;
});
export const getContactThunk = createAsyncThunk(
  "getContact",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;

    const response = await axios.get(`${BASE_URL}/contacts`, {
      params: {
        skip: payload.skip,
        limit: payload.limit,
      },
      headers: {
        Authorization: `Bearer ${AuthToken}`,
      },
    });
    return response.data;
  }
);
