import React from "react";
import Button from "../Button/Button";

const Value = (props) => {
  return (
    <div className="value">
      <div className="value-right">
        <h2 className="value-right__title">{props?.title}</h2>
        <p className="value-right__description">{props?.description}</p>
        <div className="value-right__actions">
          <Button
            path="/freetrial"
            title="طلب نسخة تجريبة"
            styles="btn btn--l btn--primary"
          />
          <Button
            path="/contact"
            title="تواصل معنا "
            styles="btn btn--l btn--secondary"
          />
        </div>
      </div>
      <div className="value-left">
        <div className="value-left__img">
          <img src={props?.img} alt={props?.title} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Value);
