import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialLinks = (props) => {
  return (
    <motion.div
      className="social"
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -250, opacity: 0 }}
      transition={{ delay: 0.6 }}
    >
      <Link
        to="https://www.facebook.com/profile.php?id=100092176303215"
        className="social-item"
        target="_blank"
        style={{ backgroundColor: props?.backgroundColor }}
      >
        <FontAwesomeIcon
          icon={faFacebookF}
          name="facebook"
          className="social-item__icon"
        />
      </Link>
      <Link
        to="#"
        className="social-item"
        target="_blank"
        style={{ backgroundColor: props?.backgroundColor }}
      >
        <FontAwesomeIcon
          icon={faTwitter}
          name="whatsapp"
          className="social-item__icon"
          target="_blank"
        />
      </Link>
      <Link
        to="https://wa.me/+966546811900"
        target="_blank"
        className="social-item"
        style={{ backgroundColor: props?.backgroundColor }}
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          name="whatsapp"
          className="social-item__icon"
        />
      </Link>
    </motion.div>
  );
};

export default React.memo(SocialLinks);
