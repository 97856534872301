import React from "react";
import ROUTES from "../../Routes/Routes";
import { Link } from "react-router-dom";
import SocialLinks from "../SocialLinks/SocialLinks";
import RolesLayout from "../../layouts/RolesLayout/RolesLayout";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../Redux/Slices/UserSlice";
import Button from "../Button/Button";

const MobileSideNav = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user?.user);
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    props.setNavState(false);
  };
  return (
    <aside className={props?.isActive ? "sidenav active" : "sidenav"}>
      <ul className="sidenav-menu">
        {ROUTES?.map((route) => {
          return (
            !route.isHidden && (
              <li
                className="sidenav-menu__item"
                key={route.id}
                onClick={() => handleClickScroll(route.id)}
              >
                <Link className="sidenav-menu__item-link" to={route.path}>
                  {route.name}
                </Link>
              </li>
            )
          );
        })}
        <RolesLayout roles={["admin"]}>
          <li
            animate={{ x: 0, opacity: 1 }}
            initial={{ x: -150, opacity: 0 }}
            transition={{ delay: 0.5, duration: 0.4, type: "spring" }}
            className="navbar-left__menu__item"
          >
            <Link to={"/admin"} className="sidenav-menu__item-link">
              صفحه الأدمن
            </Link>
          </li>
        </RolesLayout>
        <li className="navbar-left__menu__item" style={{ marginTop: 10 }}>
          {user?.user?.role ? (
            <Button
              styles="btn btn--primary btn--l"
              onClick={() => dispatch(userActions.logOut())}
              title=" تسجيل خروج"
            />
          ) : (
            <Button
              styles="btn btn--primary btn--l"
              title=" تسجيل دخول"
              path="/login"
              onClick={() => handleClickScroll("login")}
            />
          )}
        </li>
      </ul>

      <div className="sidenav-social">
        <SocialLinks />
      </div>
    </aside>
  );
};

export default React.memo(MobileSideNav);
