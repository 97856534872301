import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const Button = ({
  path,
  styles,
  onClick,
  icon,
  title,
  name,
  disabled,
  type,
}) => {
  if (path) {
    return (
      <Link name={name} to={path ?? "#"} className={styles} onClick={onClick}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {title ?? ""}
      </Link>
    );
  } else {
    return (
      <button
        name={name}
        disabled={disabled}
        className={styles}
        onClick={onClick}
        type={type}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {title ?? ""}
      </button>
    );
  }
};

export default React.memo(Button);
