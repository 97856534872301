import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "../Slices/UserSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import MetaSlice from "../Slices/MetaSlice";
import ProductSlice from "../Slices/ProductSlice";
import UsersSlice from "../Slices/UsersSlice";
import CouponeSlice from "../Slices/CouponeSlice";
import TrialsSlice from "../Slices/TrialsSlice";
import instructionsSlice from "../Slices/InstructionsSlice";
import FAQSlice from "../Slices/FAQSlice";
import TicketsSlice from "../Slices/TicketsSlice";
import ChatSlice from "../Slices/ChatSlice";
import RatingSlice from "../Slices/RatingSlice";
import ContactSlice from "../Slices/ContactSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const reducers = combineReducers({
  user: userSlice,
  metaData: MetaSlice,
  products: ProductSlice,
  users: UsersSlice,
  coupone: CouponeSlice,
  trials: TrialsSlice,
  instructions: instructionsSlice,
  faq: FAQSlice,
  tickets: TicketsSlice,
  chat: ChatSlice,
  rate: RatingSlice,
  contact: ContactSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
