import { lazy } from "react";
import { ROLES } from "../Constant/Roles";

// non LazyLoaded Pages
import Home from "../pages/Home/Home";
import Product from "../pages/ProductPage/ProductPage";

/* 
@All Lazy Loaded Pages for Saving Data Bundle for user
*/
const Ticket = lazy(() => import("../pages/Ticket"));
const Contact = lazy(() => import("../pages/Contact"));
// Login Page
const Login = lazy(() => import("../pages/Login"));

const Support = lazy(() => import("../pages/Support"));
const FAQ = lazy(() => import("../pages/FAQ"));
const Instructions = lazy(() => import("../pages/Instructions"));
// multistep Form
const FreeTrial = lazy(() => import("../layouts/MiltiStepForm"));
//chat page
const Chat = lazy(() => import("../pages/chat"));
//Admin Pages
const AdminHome = lazy(() => import("../pages/AdminHome"));
const AdminProduct = lazy(() => import("../pages/AdminAddProduct"));
const AdminFAQ = lazy(() => import("../pages/AdminFAQ"));
const AdminClients = lazy(() => import("../pages/AdminClients"));
const AdminClient = lazy(() => import("../pages/SinglePage"));
const CreateUser = lazy(() => import("../pages/AdminCreateUser"));
const AdminInstructions = lazy(() => import("../pages/AdminInstructions"));
const AdminTrialsPage = lazy(() => import("../pages/AdminTrials"));
const AdminContact = lazy(() => import("../pages/AdminContact"));
const AdminCoupoune = lazy(() => import("../pages/AdminCoupone"));

// Routes Made Here To make Sure There are no typos and it remains constant in all pages when beeing looped on
export const RoutePathes = Object.freeze({
  home: "/",
  projects: "/support",
  // clients: "/#",
  programs: "/#",
  Contactus: "/contact",
  Product: "/products/:id",
  ticket: "/ticket",
  Login: "/login",
  Chat: "ticket/chat/:id",
  FAQ: "/faq",
  Instructions: "/instructions",
  FreeTrial: "/freetrial",
  AdminPanel: "/admin",
  AddProduct: "/admin/product",
  ADDFAQ: "/admin/faq",
  AdminClients: "/admin/clients",
  AdminClient: "/admin/clients/:id",
  AdminCreateUser: "/admin/user",
  AdminInstructions: "/admin/instructions",
  AdminTrials: "/admin/trials",
  AdminContact: "/admin/contact",
  AdminCoupoune: "/admin/coupone",
});

const ROUTES = [
  //[1] Home Page
  {
    id: "home",
    name: "الرئيسية",
    path: RoutePathes.home,
    Element: Home,
    isHidden: false,
    index: true,
  },
  //[2] Support Page
  {
    id: "support",
    name: "طلب الدعم",
    path: RoutePathes.projects,
    Element: Support,
    isHidden: false,
  },
  //[3] Our Clients Ref Not a Page
  // {
  //   id: "clients",
  //   name: "عملائنا",
  //   path: RoutePathes.clients,
  //   Element: "",
  //   isHidden: false,
  // },
  // [4] Products Ref Not A Page
  {
    id: "products",
    name: "البرامج",
    path: RoutePathes.programs,
    Element: "",
    isHidden: false,
  },
  // [5] Single Product Page
  {
    id: "product",
    name: "برنامج",
    path: RoutePathes.Product,
    Element: Product,
    isHidden: true,
  },
  // [5] Contact Us Page
  {
    id: "contact",
    name: "تواصل معنا",
    path: RoutePathes.Contactus,
    Element: Contact,
    isHidden: false,
  },
  // [6] Ticket Page
  {
    id: "ticket",
    name: "الدعم عبر الرسائل",
    path: RoutePathes.ticket,
    Element: Ticket,
    isHidden: true,
    requires: [ROLES.ADMIN, ROLES.CUSTOMER],
  },
  // [7] FAQ Page
  {
    id: "faq",
    name: "الأسئلة الأكثر شيوعا",
    path: RoutePathes.FAQ,
    Element: FAQ,
    isHidden: true,
  },
  // [8] Instructions Page
  {
    id: "instructions",
    name: "الأرشادات",
    path: RoutePathes.Instructions,
    Element: Instructions,
    isHidden: true,
  },
  // [9] AdminPanel Home Page
  {
    id: "admin-panel",
    name: "لوحة الادارة ",
    path: RoutePathes.AdminPanel,
    Element: AdminHome,
    isHidden: ROLES.ADMIN ? true : false,
    requires: [ROLES.ADMIN],
  },
  // [10] AdminPanel Add-Product Page
  {
    id: "admin-addporudct",
    name: "اضافه منتج ",
    path: RoutePathes.AddProduct,
    Element: AdminProduct,
    isHidden: ROLES.ADMIN ? true : false,
    requires: [ROLES.ADMIN],
  },
  // [11] AdminPanel See All Trial Requests Page
  {
    id: "admin-trials",
    name: "طلبات النسخ التجريبية ",
    path: RoutePathes.AdminTrials,
    Element: AdminTrialsPage,
    isHidden: true,
    requires: [ROLES.ADMIN],
  },
  // [12] AdminPanel Add-FAQ Page
  {
    id: "admin-faq",
    name: "اضافه سؤال",
    path: RoutePathes.ADDFAQ,
    Element: AdminFAQ,
    isHidden: ROLES.ADMIN ? true : false,
    requires: [ROLES.ADMIN],
  },
  // [13] AdminPanel See All Clients Details page
  {
    id: "admin-client",
    name: "جميع العملاء",
    path: RoutePathes.AdminClient,
    Element: AdminClient,
    isHidden: ROLES.ADMIN ? true : false,
    requires: [ROLES.ADMIN],
  },
  // [14] AdminPanel See Customers Details Page
  {
    id: "admin-clients",
    name: "جميع العملاء",
    path: RoutePathes.AdminClients,
    Element: AdminClients,
    isHidden: ROLES.ADMIN ? true : false,
    requires: [ROLES.ADMIN],
  },
  // [15] AdminPanel coupone Page
  {
    id: "admin-coupone",
    name: "coupone",
    path: RoutePathes.AdminCoupoune,
    Element: AdminCoupoune,
    isHidden: true,
    requires: [ROLES.ADMIN],
  },
  // [16] AdminPanel Add-Instructions Page
  {
    id: "admin-instructions",
    name: "جميع العملاء",
    path: RoutePathes.AdminInstructions,
    Element: AdminInstructions,
    isHidden: true,
    requires: [ROLES.ADMIN],
  },
  // [17] AdminPanel Create-user Page
  {
    id: "create-user",
    name: " اضافة مستخدم",
    path: RoutePathes.AdminCreateUser,
    Element: CreateUser,
    isHidden: ROLES.ADMIN ? true : false,
    requires: [ROLES.ADMIN],
  },
  // [18] User MultiStep platform Page For Request Free Trials
  {
    id: "freetrial",
    name: "طلب نسخه تجريبية",
    path: RoutePathes.FreeTrial,
    Element: FreeTrial,
    isHidden: false,
  },
  // [19] Chat Page For the ticket
  {
    id: "chat",
    name: "التواصل",
    path: RoutePathes.Chat,
    Element: Chat,
    isHidden: true,
    requires: [ROLES.ADMIN || ROLES.CUSTOMER || ROLES.EMPLOYER],
  },
  // [20] Chat Page For the ticket
  {
    id: "admin-contact",
    name: "طابات التواصل",
    path: RoutePathes.AdminContact,
    Element: AdminContact,
    isHidden: true,
    requires: [ROLES.ADMIN],
  },
  // [21] Login Page
  {
    id: "login",
    name: "تسجيل الدخول",
    path: RoutePathes.Login,
    Element: Login,
    isHidden: true,
  },
];

export default ROUTES;
