import React, { Suspense } from "react";
// images and FontAwesome
import { imac } from "../../Images";
import {
  faUser,
  faCompass,
  faEye,
  faFaceSmile,
  faChartSimple,
  faPhone,
  faEnvelope,
  faLocation,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
// Redux and helpers
import { useDispatch, useSelector } from "react-redux";
import Reveal from "../../components/Reveal";
import { getMetaData } from "../../Redux/Thunk/metaDataThunk";
// Components
import Value from "../../components/Value";
import Counter from "../../components/Counter/Counter";
import GoalsItem from "../../components/GoalsItem";
import ContactItem from "../../components/ContactItem";
import Loading from "../../components/Loading/Loading";
import ProductCard from "../../components/ProductCard/ProductCard";
import Header from "../../components/Header";
import OurClients from "../../components/OurClients";
// import FloaTSideBtn from "../../layouts/ScrollToTop/ScrollToTop";

import {
  deleteProductThunk,
  getProductsThunk,
  updateProductByIdThunk,
} from "../../Redux/Thunk/ProductThunk";
import { productAction } from "../../Redux/Slices/ProductSlice";

const Home = () => {
  // const [showTop, setShowTop] = React.useState(false);
  // const scrollTop = () => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // };

  // const showSideButtons = React.useCallback(() => {
  //   window.scrollTo(0, 0);
  //   setInterval(() => {
  //     window.addEventListener("scroll", () => {
  //       if (window.scrollY > 400) {
  //         document
  //           .querySelectorAll(".sidebtn")
  //           ?.forEach((i) => i.classList.add("active"));
  //         setShowTop(true);
  //       } else {
  //         setShowTop(false);
  //         document
  //           .querySelectorAll(".sidebtn")
  //           ?.forEach((i) => i.classList.remove("active"));
  //       }
  //     });
  //   }, 3000);
  // }, []);

  // React.useEffect(() => {
  //   showSideButtons();
  //   return () => {
  //     clearInterval(showSideButtons);

  //     scrollTop();
  //   };
  //   // 👇️ scroll to top on Click
  // }, [showSideButtons]);

  const metaData = useSelector((state) => state?.metaData?.data?.[0]);
  const productData = useSelector((state) => state.products.data);
  const dispatch = useDispatch();
  //get Products if no products
  React.useEffect(() => {
    dispatch(getProductsThunk({ skip: 0, limit: 20 }));
  }, [dispatch]);
  // Get Meta Data
  React.useEffect(() => {
    if (!metaData) {
      dispatch(getMetaData());
    }
  }, [dispatch, metaData]);

  const [prodcutEditableContent, setProdcutEditableContent] = React.useState({
    body: {},
    editable: false,
  });

  const productEditContent = React.useCallback(
    (id, data) => {
      dispatch(productAction.editProduct({ id, data }));
    },
    [dispatch]
  );

  const onProductSubmit = React.useCallback(
    (id, data) => {
      dispatch(updateProductByIdThunk({ id, data }));
    },
    [dispatch]
  );

  return (
    <main className="home">
      {/* <FloaTSideBtn styles={"sidebtn scroll-top"} onClick={scrollTop} />
      <FloaTSideBtn styles={" sidebtn support-btn"} support /> */}
      {/* Header  section */}
      <Reveal>
        <Suspense fallback={<Loading />}>
          <Header />
        </Suspense>
      </Reveal>
      {/* Value Section */}
      <Reveal>
        <Suspense fallback={<Loading />}>
          <section className="home-value">
            <Value
              img={imac}
              title="القيمة التى تحصل عليها عند استخدامك تطبيقاتنا"
              description="تقوم الشركة بتقديــم حلــول الأعمــال للمؤسســات والشــركات مــن خلال
              الفهــم الدقيــق لإحتياجــات العــملاء حيــث نوفــر برامــج محاســبية ســهلة
              الإســتخدام ومرنــة فــي إدارة وتنظيــم الحســابات ودقــة
              التقاريــر ممــا يحقــق أهــداف المؤسســات والشــركات كمــا
              يســتمر فريــق العمــل بتطويــر النظــام بشــكل دوري
              ومســتمر بالإضافــة إلــى تصميــم الأنظمــة والبرامــج
              المتخصصـة وفقـاً لمتطلبـات العمـل وذلـك لتوافـر مطـوري
              البرامـج لتصميـم أيـة أنظمـة فـي كافـة المجـالات المختلفـة
              مــع توافــر الدعــم الفنــى علــى مــدار الســاعة ليبقــى دائمــاً
              البرنامج الرائد فى عالم البرمجيات المحاسبية العربية
              "
            />
          </section>
        </Suspense>
      </Reveal>
      {/* Our Clients Section */}
      <Reveal>
        <section className="home-clients" id="clients">
          <Suspense fallback={<Loading />}>
            <OurClients
              title={"انضم الى"}
              coloredTitle="عملائنا"
              description="تقدم الشركة نظام محاسبي متكامل شامل حسابات التكاليف يدعم الفاتورة الالكترونية
              يمكن العميل من متابعة ومراقبة حسابات جميع الفروع
              حيث توفر ادارة مركزية للفروع مع تحكم كامل بالمخزون 
              مع تحكم لامحدود بصلاحيات المستخدمين للنظام 
                 كما تتيح للعميل تقارير تفصيلية لمتابعة العمل بشكل مستمر لذا كونت شركة وافي القمة قائمة بالعملاء التي تفخر بهم من شتى انحاء المملكة كما تسعى جادة الى التوسع في تقديم خدماتها إقليمياً وحرصا على استمرارية العلاقة مع العملاء و كسب رضاهم فقد تم العمل على تقديم خدمات ما بعد البيع على اعلى مستوى من خلال فريق احترافي متخصص."
              // imgs={[]}
            />
          </Suspense>
        </section>
      </Reveal>
      {/* Goals Section */}
      <Reveal>
        <section className="home-goals">
          <div className="container">
            <h2 className="home-goals-title">اهدافنا</h2>

            <div className="home-goals-items">
              <GoalsItem
                title="رسالتنا"
                icon={faCompass}
                text="تلبّيةً احتياجات كافة العملاء ، والتأكيد على تقديم خدمات البيع وما بعد البيع، مع المحافظة على تحقيق مستوى متميز ومرضي لجميع العملاء بكل مصداقية وشفافية"
              />
              <GoalsItem
                title="رؤيتنا"
                icon={faEye}
                text="نسعى لنكون مقدم الخدمات البرمجية الأكثر موثوقية في المملكة والاكثر ارضاءا لجميع العملاء ."
              />
              <GoalsItem
                title="خدماتنا"
                icon={faGear}
                text="تقديــم حلــول الأعمــال للمؤسســات والشــركات مــن خلال
                الفهــم الدقيــق لإحتياجــات العملاء و توفير برامج محاسبية و إداريه تساهم فى تطوير أليه العمل داخل المؤسسة ."
              />
            </div>
          </div>
        </section>
      </Reveal>
      {/* satisfaction */}
      <Reveal>
        <section className="home-counter">
          <Counter
            percentage
            icon={faFaceSmile}
            title="رضى العملاء"
            from={0}
            to={Number(metaData?.satisfaction_rate ?? 70)}
          />
          <Counter
            icon={faUser}
            title="عملائنا"
            from={0}
            to={
              Number(metaData?.customers ?? 2500) < 1250
                ? 2500
                : Number(metaData?.customers)
            }
          />
          <Counter
            icon={faChartSimple}
            title="الزيارات"
            from={0}
            to={Number(metaData?.visitors ?? 10000)}
          />
        </section>
      </Reveal>
      {/* Products Sectoion */}
      <Reveal>
        <section className="products" id="products">
          <div className="products__title">
            <h2 className="products__title-top">منتجاتنا</h2>
            <p className="products__title-bottom">
              افضـل البرامـج الموجـوده بالمملكـة فيمكنـك لإنشــاء تقاريــر أو
              ســحابياً اســتخدامه محليــاً نشاطك التجاري
            </p>

            <>
              <div className="products__products">
                {productData?.map((product) => {
                  return (
                    <ProductCard
                      editable={prodcutEditableContent.editable}
                      product={product}
                      key={product.id}
                      btnTitle={product.btnTitle}
                      onEdit={productEditContent}
                      handleEdit={() => {
                        setProdcutEditableContent((old) => {
                          return { ...old, editable: !old.editable };
                        });
                      }}
                      onDelete={() => {
                        dispatch(deleteProductThunk(product.id));
                      }}
                      onSubmit={onProductSubmit}
                    />
                  );
                })}
              </div>
            </>
          </div>
        </section>
      </Reveal>
      {/* CONTACT US SECTION */}
      <Reveal>
        <section className="home-contact">
          <div className="home-contact-right">
            <h2 className="home-contact-right__title">
              تواصل <span>معنا</span>
            </h2>
            <p className="home-contact-right__description">
              يمكنك التواصل معنا عبر العديد من منصات التواصل الإجتماعى
            </p>
          </div>
          <div className="home-contact-left">
            <div className="home-contact-left__items">
              <ContactItem
                textAlign="center"
                yAnimate={0}
                yInitial={100}
                icon={faPhone}
                title="الهاتف"
                desc="0546811900"
                path="tel:+966546811900"
                column
              />
              <ContactItem
                textAlign="center"
                yAnimate={0}
                yInitial={100}
                icon={faEnvelope}
                title="البريد الآلكترونى"
                desc="Wafitop@wafitop.com"
                path="mailto:wafitop@wafi.com"
                column
              />
              <ContactItem
                textAlign="center"
                yAnimate={0}
                yInitial={100}
                icon={faLocation}
                desc=" خميس مشيط طريق الملك فهد"
                path="https://goo.gl/maps/TnnxeYVzjBqNTqQm6"
                title="العنوان"
                column
              />
            </div>
          </div>
        </section>
      </Reveal>
    </main>
  );
};

export default Home;
