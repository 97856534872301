import { createSlice } from "@reduxjs/toolkit";
import {
  createUsersThunk,
  getUsersById,
  getUsersByRole,
} from "../Thunk/UsersThunk";
import { Link } from "react-router-dom";
import { response_status } from "../../Constant/Status";

const initialState = {
  data: [],
  loading: false,
  status: null,
  dataLength: 0,
  createUserStatus: null,
  userData: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.createUserStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get users by Role
    builder.addCase(getUsersByRole.fulfilled, (state, action) => {
      state.loading = false;
      state.status = response_status.success;
      state.dataLength = action.payload.dataLength;
      state.data = action.payload.data.map((item) => {
        if (!item.notes) {
          item.notes = "لا يوجد ملاجظات";
        }
        if (!item.email) {
          item.email = "لا يوجد ايميل";
        }
        if (!item.phone) {
          item.phone = "لا يوجد جوال";
        }
        item.createdAt = new Date().toLocaleDateString();
        item.updatedAt = new Date().toLocaleDateString();
        item.details = (
          <Link
            to={`/admin/clients/${item.id}`}
            className="contactField btn btn--primary btn--s"
          >
            المزيد
          </Link>
        );
        return item;
      });
    });
    builder.addCase(getUsersByRole.pending, (state, action) => {
      state.status = response_status.none;
      state.data = [];
      state.loading = true;
    });

    builder.addCase(getUsersByRole.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.status = response_status.fail;
    });
    // [2] Create user
    builder.addCase(createUsersThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.createUserStatus = response_status.success;
    });
    builder.addCase(createUsersThunk.pending, (state, action) => {
      state.createUserStatus = response_status.none;
      state.loading = true;
    });
    builder.addCase(createUsersThunk.rejected, (state, action) => {
      state.loading = false;
      state.createUserStatus = response_status.fail;
    });
    // [3] get  users by id
    builder.addCase(getUsersById.fulfilled, (state, action) => {
      state.loading = false;
      state.status = response_status.success;
      state.userData = action.payload;
    });
    builder.addCase(getUsersById.pending, (state, action) => {
      state.status = response_status.none;
      state.loading = true;
    });
    builder.addCase(getUsersById.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
