import { response_status } from "../../Constant/Status";
import { addChatThunk, getChatThunk } from "../Thunk/ChatThunk";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  data: [],
  loading: false,
  ticket_id: null,
  status: null,
  sendStatus: null,
};
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addReplay: (state, action) => {
      state.data = [...state.data, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.status = response_status.success;
      state.ticket_id = action.payload?.[0]?.id;
    });
    builder.addCase(getChatThunk.pending, (state, action) => {
      state.loading = false;
      state.status = response_status.none;
    });
    builder.addCase(getChatThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });

    // [2] set seen Ticket
    builder.addCase(addChatThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.sendStatus = response_status.success;
    });
    builder.addCase(addChatThunk.pending, (state, action) => {
      state.loading = false;
      state.sendStatus = response_status.none;
    });
    builder.addCase(addChatThunk.rejected, (state, action) => {
      state.loading = false;
      state.sendStatus = response_status.fail;
    });
  },
});

export default chatSlice.reducer;
export const chatActions = chatSlice.actions;
