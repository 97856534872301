import React from "react";

const OurClients = (props) => {
  return (
    <div className="ourClients">
      <h2 className="ourClients-title">
        {props?.title} <span> {props.coloredTitle} </span>
      </h2>
      <p className="ourClients-description">{props?.description}</p>

      {/* <div className="ourClients__imgs">
        {props.imgs.map((img, i) => {
          return (
            <img
              src={img}
              key={i}
              alt={`img${i}`}
              className="ourClients__imgs__item"
            />
          );
        })}
      </div> */}
    </div>
  );
};

export default React.memo(OurClients);
