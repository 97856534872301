import { createSlice } from "@reduxjs/toolkit";
import {
  addProductThunk,
  deleteProductThunk,
  getProductByIdThunk,
  getProductsThunk,
  updateProductByIdThunk,
} from "../Thunk/ProductThunk";
import { response_status } from "../../Constant/Status";
const initialState = {
  data: [],
  currentProduct: null,
  dataLength: 0,
  status: null,
  loading: false,
  addPorductStatus: null,
};
const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    editProduct: (state, action) => {
      state.data = state.data.map((v) => {
        if (v.id === action.payload.id) {
          return action.payload.data;
        }
        return v;
      });
    },
    deleteProduct: (state, action) => {
      return (state.data = state.data.filter(
        (item) => item.id !== action.payload
      ));
    },
    setStatus: (state, action) => {
      state.addPorductStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsThunk.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.dataLength = action.payload.dataLength;
      state.loading = false;
      state.status = response_status.success;
    });
    builder.addCase(getProductsThunk.pending, (state, action) => {
      state.data = [];
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(getProductsThunk.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.status = response_status.fail;
    });

    // [2] Delete Product
    builder.addCase(deleteProductThunk.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.loading = false;
      state.status = response_status.success;
    });
    builder.addCase(deleteProductThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(deleteProductThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });
    // [3] Get Product by id
    builder.addCase(getProductByIdThunk.fulfilled, (state, action) => {
      state.currentProduct = action.payload[0];
      state.loading = false;
      state.status = response_status.success;
    });
    builder.addCase(getProductByIdThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(getProductByIdThunk.rejected, (state, action) => {
      state.currentProduct = undefined;
      state.loading = false;
      state.status = response_status.fail;
    });
    // [4] update product by id
    builder.addCase(updateProductByIdThunk.fulfilled, (state, action) => {
      state.data = state.data.map((v) => {
        if (action.payload.id) {
          return action.payload.data;
        }
        return v;
      });
      if (state.currentProduct) {
        state.currentProduct = action.payload;
      }
      state.loading = false;
      state.status = response_status.success;
    });

    builder.addCase(updateProductByIdThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(updateProductByIdThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });

    // Add Product
    builder.addCase(addProductThunk.fulfilled, (state, action) => {
      state.status = action.payload;
      state.loading = false;
      state.addPorductStatus = response_status.success;
    });
    builder.addCase(addProductThunk.pending, (state, action) => {
      state.loading = true;
      state.status = null;
      state.addPorductStatus = response_status.none;
    });
    builder.addCase(addProductThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = action.error.code;
      state.addPorductStatus = response_status.fail;
    });
  },
});

export default productsSlice.reducer;
export const productAction = productsSlice.actions;
