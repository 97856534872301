import React from "react";
import { HashRouter as Router } from "react-router-dom";
import AnimatedRoutes from "../AnimatedRoutes/AnimatedRoutes";

function App() {
  return (
    <main className="App">
      <Router>
        <AnimatedRoutes />
      </Router>
    </main>
  );
}

export default App;
