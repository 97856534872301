import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { userActions } from "../Slices/UserSlice";

export const loginThunk = createAsyncThunk("login", loginThunkcallback);

export async function loginThunkcallback(payload, thunk) {
  let response = null;
  try {
    response = await axios.post(BASE_URL + "/login", payload, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  } catch (err) {
    // thunk.dispatch(userActions.login(null));
    console.log(err);
  }
  return response.data;
}

export const refreshTokenThunk = createAsyncThunk(
  "refreshToken",
  async (_, thunk) => {
    let response = null;
    try {
      response = await axios.get(`${BASE_URL}/token`, {
        // timeout: 10000,
        withCredentials: true,
      });
    } catch (err) {
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);
