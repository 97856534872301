import React from "react";
import "./ErrorFallBack.scss";
const ErrorFallBack = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" className="error">
      <p className="error-title">Something went wrong:</p>
      <pre style={{ color: "red" }} className="error-msg">
        {error.message}
      </pre>
    </div>
  );
};

export default ErrorFallBack;
